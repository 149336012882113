import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _55722f16 = () => interopDefault(import('../pages/agent-terms-of-use.vue' /* webpackChunkName: "pages/agent-terms-of-use" */))
const _70ae5bfb = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4cc3bd02 = () => interopDefault(import('../pages/book.vue' /* webpackChunkName: "pages/book" */))
const _7e76309e = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _82010c46 = () => interopDefault(import('../pages/flights.vue' /* webpackChunkName: "pages/flights" */))
const _7a9e8c18 = () => interopDefault(import('../pages/hotels/index.vue' /* webpackChunkName: "pages/hotels/index" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _04542a83 = () => interopDefault(import('../pages/packages.vue' /* webpackChunkName: "pages/packages" */))
const _7111f702 = () => interopDefault(import('../pages/pay.vue' /* webpackChunkName: "pages/pay" */))
const _7e9105a6 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _fc4a92fa = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _22a47626 = () => interopDefault(import('../pages/reservation.vue' /* webpackChunkName: "pages/reservation" */))
const _ce1ebd28 = () => interopDefault(import('../pages/searches.vue' /* webpackChunkName: "pages/searches" */))
const _2deef8e9 = () => interopDefault(import('../pages/sso.vue' /* webpackChunkName: "pages/sso" */))
const _4a16b12d = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _e447567e = () => interopDefault(import('../pages/tickets.vue' /* webpackChunkName: "pages/tickets" */))
const _2eba8ca2 = () => interopDefault(import('../pages/account/reservations/index.vue' /* webpackChunkName: "pages/account/reservations/index" */))
const _19a7f2a4 = () => interopDefault(import('../pages/dashboard/bookings/index.vue' /* webpackChunkName: "pages/dashboard/bookings/index" */))
const _7c1d91ea = () => interopDefault(import('../pages/hotels/israel.vue' /* webpackChunkName: "pages/hotels/israel" */))
const _f61c5f5c = () => interopDefault(import('../pages/hotels/search.vue' /* webpackChunkName: "pages/hotels/search" */))
const _6f7cfe9e = () => interopDefault(import('../pages/widgets/search.vue' /* webpackChunkName: "pages/widgets/search" */))
const _54a258d7 = () => interopDefault(import('../pages/hotels/places/ae/dubai.vue' /* webpackChunkName: "pages/hotels/places/ae/dubai" */))
const _b496eed2 = () => interopDefault(import('../pages/hotels/places/de/berlin.vue' /* webpackChunkName: "pages/hotels/places/de/berlin" */))
const _be3b449a = () => interopDefault(import('../pages/hotels/places/es/barcelona.vue' /* webpackChunkName: "pages/hotels/places/es/barcelona" */))
const _52d7c612 = () => interopDefault(import('../pages/hotels/places/mx/cancun.vue' /* webpackChunkName: "pages/hotels/places/mx/cancun" */))
const _6975cdee = () => interopDefault(import('../pages/hotels/places/nl/amsterdam.vue' /* webpackChunkName: "pages/hotels/places/nl/amsterdam" */))
const _778c272c = () => interopDefault(import('../pages/account/reservations/_uuid.vue' /* webpackChunkName: "pages/account/reservations/_uuid" */))
const _eea93314 = () => interopDefault(import('../pages/dashboard/bookings/_uuid.vue' /* webpackChunkName: "pages/dashboard/bookings/_uuid" */))
const _568b8370 = () => interopDefault(import('../pages/hotels/destination/_countrySlug/index.vue' /* webpackChunkName: "pages/hotels/destination/_countrySlug/index" */))
const _7e775a48 = () => interopDefault(import('../pages/hotels/place/_placeId/results.vue' /* webpackChunkName: "pages/hotels/place/_placeId/results" */))
const _18ec7bef = () => interopDefault(import('../pages/hotels/destination/_countrySlug/_destinationSlug/index.vue' /* webpackChunkName: "pages/hotels/destination/_countrySlug/_destinationSlug/index" */))
const _e5503a8a = () => interopDefault(import('../pages/hotels/place/_countrySlug/_hotelId.vue' /* webpackChunkName: "pages/hotels/place/_countrySlug/_hotelId" */))
const _5fa60fd3 = () => interopDefault(import('../pages/hotels/destination/_countrySlug/_destinationSlug/results.vue' /* webpackChunkName: "pages/hotels/destination/_countrySlug/_destinationSlug/results" */))
const _d9cdb59a = () => interopDefault(import('../pages/hotels/destination/_countrySlug/_destinationSlug/_hotelSlug.vue' /* webpackChunkName: "pages/hotels/destination/_countrySlug/_destinationSlug/_hotelSlug" */))
const _7b08ac1a = () => interopDefault(import('../pages/transaction/_transactionUuid/error.vue' /* webpackChunkName: "pages/transaction/_transactionUuid/error" */))
const _141eae15 = () => interopDefault(import('../pages/transaction/_transactionUuid/success.vue' /* webpackChunkName: "pages/transaction/_transactionUuid/success" */))
const _003ac94d = () => interopDefault(import('../pages/hotels/_countrySlug/_citySlug/_hotelSlug.vue' /* webpackChunkName: "pages/hotels/_countrySlug/_citySlug/_hotelSlug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___en"
  }, {
    path: "/app",
    component: _70ae5bfb,
    name: "app___en"
  }, {
    path: "/ar",
    component: _98173ca8,
    name: "index___ar"
  }, {
    path: "/bg",
    component: _98173ca8,
    name: "index___bg"
  }, {
    path: "/book",
    component: _4cc3bd02,
    name: "book___en"
  }, {
    path: "/ca",
    component: _98173ca8,
    name: "index___ca"
  }, {
    path: "/cs",
    component: _98173ca8,
    name: "index___cs"
  }, {
    path: "/da",
    component: _98173ca8,
    name: "index___da"
  }, {
    path: "/de",
    component: _98173ca8,
    name: "index___de"
  }, {
    path: "/el",
    component: _98173ca8,
    name: "index___el"
  }, {
    path: "/es",
    component: _98173ca8,
    name: "index___es"
  }, {
    path: "/et",
    component: _98173ca8,
    name: "index___et"
  }, {
    path: "/favorites",
    component: _7e76309e,
    name: "favorites___en"
  }, {
    path: "/fi",
    component: _98173ca8,
    name: "index___fi"
  }, {
    path: "/flights",
    component: _82010c46,
    name: "flights___en"
  }, {
    path: "/fr",
    component: _98173ca8,
    name: "index___fr"
  }, {
    path: "/he",
    component: _98173ca8,
    name: "index___he"
  }, {
    path: "/hotels",
    component: _7a9e8c18,
    name: "hotels___en"
  }, {
    path: "/hr",
    component: _98173ca8,
    name: "index___hr"
  }, {
    path: "/hu",
    component: _98173ca8,
    name: "index___hu"
  }, {
    path: "/id",
    component: _98173ca8,
    name: "index___id"
  }, {
    path: "/is",
    component: _98173ca8,
    name: "index___is"
  }, {
    path: "/it",
    component: _98173ca8,
    name: "index___it"
  }, {
    path: "/ja",
    component: _98173ca8,
    name: "index___ja"
  }, {
    path: "/ko",
    component: _98173ca8,
    name: "index___ko"
  }, {
    path: "/login",
    component: _7d9c04c3,
    name: "login___en"
  }, {
    path: "/lt",
    component: _98173ca8,
    name: "index___lt"
  }, {
    path: "/ms",
    component: _98173ca8,
    name: "index___ms"
  }, {
    path: "/nl",
    component: _98173ca8,
    name: "index___nl"
  }, {
    path: "/packages",
    component: _04542a83,
    name: "packages___en"
  }, {
    path: "/pay",
    component: _7111f702,
    name: "pay___en"
  }, {
    path: "/pl",
    component: _98173ca8,
    name: "index___pl"
  }, {
    path: "/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___en"
  }, {
    path: "/profile",
    component: _fc4a92fa,
    name: "profile___en"
  }, {
    path: "/pt",
    component: _98173ca8,
    name: "index___pt"
  }, {
    path: "/pt-br",
    component: _98173ca8,
    name: "index___pt-br"
  }, {
    path: "/reservation",
    component: _22a47626,
    name: "reservation___en"
  }, {
    path: "/ro",
    component: _98173ca8,
    name: "index___ro"
  }, {
    path: "/ru",
    component: _98173ca8,
    name: "index___ru"
  }, {
    path: "/searches",
    component: _ce1ebd28,
    name: "searches___en"
  }, {
    path: "/sk",
    component: _98173ca8,
    name: "index___sk"
  }, {
    path: "/sl",
    component: _98173ca8,
    name: "index___sl"
  }, {
    path: "/sr",
    component: _98173ca8,
    name: "index___sr"
  }, {
    path: "/sso",
    component: _2deef8e9,
    name: "sso___en"
  }, {
    path: "/sv",
    component: _98173ca8,
    name: "index___sv"
  }, {
    path: "/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___en"
  }, {
    path: "/th",
    component: _98173ca8,
    name: "index___th"
  }, {
    path: "/tickets",
    component: _e447567e,
    name: "tickets___en"
  }, {
    path: "/tl-ph",
    component: _98173ca8,
    name: "index___tl-ph"
  }, {
    path: "/tr",
    component: _98173ca8,
    name: "index___tr"
  }, {
    path: "/uk",
    component: _98173ca8,
    name: "index___uk"
  }, {
    path: "/vi",
    component: _98173ca8,
    name: "index___vi"
  }, {
    path: "/zh-cn",
    component: _98173ca8,
    name: "index___zh-cn"
  }, {
    path: "/zh-tw",
    component: _98173ca8,
    name: "index___zh-tw"
  }, {
    path: "/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___en"
  }, {
    path: "/ar/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___ar"
  }, {
    path: "/ar/app",
    component: _70ae5bfb,
    name: "app___ar"
  }, {
    path: "/ar/book",
    component: _4cc3bd02,
    name: "book___ar"
  }, {
    path: "/ar/favorites",
    component: _7e76309e,
    name: "favorites___ar"
  }, {
    path: "/ar/flights",
    component: _82010c46,
    name: "flights___ar"
  }, {
    path: "/ar/hotels",
    component: _7a9e8c18,
    name: "hotels___ar"
  }, {
    path: "/ar/login",
    component: _7d9c04c3,
    name: "login___ar"
  }, {
    path: "/ar/packages",
    component: _04542a83,
    name: "packages___ar"
  }, {
    path: "/ar/pay",
    component: _7111f702,
    name: "pay___ar"
  }, {
    path: "/ar/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___ar"
  }, {
    path: "/ar/profile",
    component: _fc4a92fa,
    name: "profile___ar"
  }, {
    path: "/ar/reservation",
    component: _22a47626,
    name: "reservation___ar"
  }, {
    path: "/ar/searches",
    component: _ce1ebd28,
    name: "searches___ar"
  }, {
    path: "/ar/sso",
    component: _2deef8e9,
    name: "sso___ar"
  }, {
    path: "/ar/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___ar"
  }, {
    path: "/ar/tickets",
    component: _e447567e,
    name: "tickets___ar"
  }, {
    path: "/bg/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___bg"
  }, {
    path: "/bg/app",
    component: _70ae5bfb,
    name: "app___bg"
  }, {
    path: "/bg/book",
    component: _4cc3bd02,
    name: "book___bg"
  }, {
    path: "/bg/favorites",
    component: _7e76309e,
    name: "favorites___bg"
  }, {
    path: "/bg/flights",
    component: _82010c46,
    name: "flights___bg"
  }, {
    path: "/bg/hotels",
    component: _7a9e8c18,
    name: "hotels___bg"
  }, {
    path: "/bg/login",
    component: _7d9c04c3,
    name: "login___bg"
  }, {
    path: "/bg/packages",
    component: _04542a83,
    name: "packages___bg"
  }, {
    path: "/bg/pay",
    component: _7111f702,
    name: "pay___bg"
  }, {
    path: "/bg/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___bg"
  }, {
    path: "/bg/profile",
    component: _fc4a92fa,
    name: "profile___bg"
  }, {
    path: "/bg/reservation",
    component: _22a47626,
    name: "reservation___bg"
  }, {
    path: "/bg/searches",
    component: _ce1ebd28,
    name: "searches___bg"
  }, {
    path: "/bg/sso",
    component: _2deef8e9,
    name: "sso___bg"
  }, {
    path: "/bg/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___bg"
  }, {
    path: "/bg/tickets",
    component: _e447567e,
    name: "tickets___bg"
  }, {
    path: "/ca/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___ca"
  }, {
    path: "/ca/app",
    component: _70ae5bfb,
    name: "app___ca"
  }, {
    path: "/ca/book",
    component: _4cc3bd02,
    name: "book___ca"
  }, {
    path: "/ca/favorites",
    component: _7e76309e,
    name: "favorites___ca"
  }, {
    path: "/ca/flights",
    component: _82010c46,
    name: "flights___ca"
  }, {
    path: "/ca/hotels",
    component: _7a9e8c18,
    name: "hotels___ca"
  }, {
    path: "/ca/login",
    component: _7d9c04c3,
    name: "login___ca"
  }, {
    path: "/ca/packages",
    component: _04542a83,
    name: "packages___ca"
  }, {
    path: "/ca/pay",
    component: _7111f702,
    name: "pay___ca"
  }, {
    path: "/ca/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___ca"
  }, {
    path: "/ca/profile",
    component: _fc4a92fa,
    name: "profile___ca"
  }, {
    path: "/ca/reservation",
    component: _22a47626,
    name: "reservation___ca"
  }, {
    path: "/ca/searches",
    component: _ce1ebd28,
    name: "searches___ca"
  }, {
    path: "/ca/sso",
    component: _2deef8e9,
    name: "sso___ca"
  }, {
    path: "/ca/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___ca"
  }, {
    path: "/ca/tickets",
    component: _e447567e,
    name: "tickets___ca"
  }, {
    path: "/cs/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___cs"
  }, {
    path: "/cs/app",
    component: _70ae5bfb,
    name: "app___cs"
  }, {
    path: "/cs/book",
    component: _4cc3bd02,
    name: "book___cs"
  }, {
    path: "/cs/favorites",
    component: _7e76309e,
    name: "favorites___cs"
  }, {
    path: "/cs/flights",
    component: _82010c46,
    name: "flights___cs"
  }, {
    path: "/cs/hotels",
    component: _7a9e8c18,
    name: "hotels___cs"
  }, {
    path: "/cs/login",
    component: _7d9c04c3,
    name: "login___cs"
  }, {
    path: "/cs/packages",
    component: _04542a83,
    name: "packages___cs"
  }, {
    path: "/cs/pay",
    component: _7111f702,
    name: "pay___cs"
  }, {
    path: "/cs/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___cs"
  }, {
    path: "/cs/profile",
    component: _fc4a92fa,
    name: "profile___cs"
  }, {
    path: "/cs/reservation",
    component: _22a47626,
    name: "reservation___cs"
  }, {
    path: "/cs/searches",
    component: _ce1ebd28,
    name: "searches___cs"
  }, {
    path: "/cs/sso",
    component: _2deef8e9,
    name: "sso___cs"
  }, {
    path: "/cs/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___cs"
  }, {
    path: "/cs/tickets",
    component: _e447567e,
    name: "tickets___cs"
  }, {
    path: "/da/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___da"
  }, {
    path: "/da/app",
    component: _70ae5bfb,
    name: "app___da"
  }, {
    path: "/da/book",
    component: _4cc3bd02,
    name: "book___da"
  }, {
    path: "/da/favorites",
    component: _7e76309e,
    name: "favorites___da"
  }, {
    path: "/da/flights",
    component: _82010c46,
    name: "flights___da"
  }, {
    path: "/da/hotels",
    component: _7a9e8c18,
    name: "hotels___da"
  }, {
    path: "/da/login",
    component: _7d9c04c3,
    name: "login___da"
  }, {
    path: "/da/packages",
    component: _04542a83,
    name: "packages___da"
  }, {
    path: "/da/pay",
    component: _7111f702,
    name: "pay___da"
  }, {
    path: "/da/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___da"
  }, {
    path: "/da/profile",
    component: _fc4a92fa,
    name: "profile___da"
  }, {
    path: "/da/reservation",
    component: _22a47626,
    name: "reservation___da"
  }, {
    path: "/da/searches",
    component: _ce1ebd28,
    name: "searches___da"
  }, {
    path: "/da/sso",
    component: _2deef8e9,
    name: "sso___da"
  }, {
    path: "/da/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___da"
  }, {
    path: "/da/tickets",
    component: _e447567e,
    name: "tickets___da"
  }, {
    path: "/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___en"
  }, {
    path: "/de/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___de"
  }, {
    path: "/de/app",
    component: _70ae5bfb,
    name: "app___de"
  }, {
    path: "/de/book",
    component: _4cc3bd02,
    name: "book___de"
  }, {
    path: "/de/favorites",
    component: _7e76309e,
    name: "favorites___de"
  }, {
    path: "/de/flights",
    component: _82010c46,
    name: "flights___de"
  }, {
    path: "/de/hotels",
    component: _7a9e8c18,
    name: "hotels___de"
  }, {
    path: "/de/login",
    component: _7d9c04c3,
    name: "login___de"
  }, {
    path: "/de/packages",
    component: _04542a83,
    name: "packages___de"
  }, {
    path: "/de/pay",
    component: _7111f702,
    name: "pay___de"
  }, {
    path: "/de/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___de"
  }, {
    path: "/de/profile",
    component: _fc4a92fa,
    name: "profile___de"
  }, {
    path: "/de/reservation",
    component: _22a47626,
    name: "reservation___de"
  }, {
    path: "/de/searches",
    component: _ce1ebd28,
    name: "searches___de"
  }, {
    path: "/de/sso",
    component: _2deef8e9,
    name: "sso___de"
  }, {
    path: "/de/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___de"
  }, {
    path: "/de/tickets",
    component: _e447567e,
    name: "tickets___de"
  }, {
    path: "/el/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___el"
  }, {
    path: "/el/app",
    component: _70ae5bfb,
    name: "app___el"
  }, {
    path: "/el/book",
    component: _4cc3bd02,
    name: "book___el"
  }, {
    path: "/el/favorites",
    component: _7e76309e,
    name: "favorites___el"
  }, {
    path: "/el/flights",
    component: _82010c46,
    name: "flights___el"
  }, {
    path: "/el/hotels",
    component: _7a9e8c18,
    name: "hotels___el"
  }, {
    path: "/el/login",
    component: _7d9c04c3,
    name: "login___el"
  }, {
    path: "/el/packages",
    component: _04542a83,
    name: "packages___el"
  }, {
    path: "/el/pay",
    component: _7111f702,
    name: "pay___el"
  }, {
    path: "/el/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___el"
  }, {
    path: "/el/profile",
    component: _fc4a92fa,
    name: "profile___el"
  }, {
    path: "/el/reservation",
    component: _22a47626,
    name: "reservation___el"
  }, {
    path: "/el/searches",
    component: _ce1ebd28,
    name: "searches___el"
  }, {
    path: "/el/sso",
    component: _2deef8e9,
    name: "sso___el"
  }, {
    path: "/el/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___el"
  }, {
    path: "/el/tickets",
    component: _e447567e,
    name: "tickets___el"
  }, {
    path: "/es/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___es"
  }, {
    path: "/es/app",
    component: _70ae5bfb,
    name: "app___es"
  }, {
    path: "/es/book",
    component: _4cc3bd02,
    name: "book___es"
  }, {
    path: "/es/favorites",
    component: _7e76309e,
    name: "favorites___es"
  }, {
    path: "/es/flights",
    component: _82010c46,
    name: "flights___es"
  }, {
    path: "/es/hotels",
    component: _7a9e8c18,
    name: "hotels___es"
  }, {
    path: "/es/login",
    component: _7d9c04c3,
    name: "login___es"
  }, {
    path: "/es/packages",
    component: _04542a83,
    name: "packages___es"
  }, {
    path: "/es/pay",
    component: _7111f702,
    name: "pay___es"
  }, {
    path: "/es/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___es"
  }, {
    path: "/es/profile",
    component: _fc4a92fa,
    name: "profile___es"
  }, {
    path: "/es/reservation",
    component: _22a47626,
    name: "reservation___es"
  }, {
    path: "/es/searches",
    component: _ce1ebd28,
    name: "searches___es"
  }, {
    path: "/es/sso",
    component: _2deef8e9,
    name: "sso___es"
  }, {
    path: "/es/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___es"
  }, {
    path: "/es/tickets",
    component: _e447567e,
    name: "tickets___es"
  }, {
    path: "/et/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___et"
  }, {
    path: "/et/app",
    component: _70ae5bfb,
    name: "app___et"
  }, {
    path: "/et/book",
    component: _4cc3bd02,
    name: "book___et"
  }, {
    path: "/et/favorites",
    component: _7e76309e,
    name: "favorites___et"
  }, {
    path: "/et/flights",
    component: _82010c46,
    name: "flights___et"
  }, {
    path: "/et/hotels",
    component: _7a9e8c18,
    name: "hotels___et"
  }, {
    path: "/et/login",
    component: _7d9c04c3,
    name: "login___et"
  }, {
    path: "/et/packages",
    component: _04542a83,
    name: "packages___et"
  }, {
    path: "/et/pay",
    component: _7111f702,
    name: "pay___et"
  }, {
    path: "/et/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___et"
  }, {
    path: "/et/profile",
    component: _fc4a92fa,
    name: "profile___et"
  }, {
    path: "/et/reservation",
    component: _22a47626,
    name: "reservation___et"
  }, {
    path: "/et/searches",
    component: _ce1ebd28,
    name: "searches___et"
  }, {
    path: "/et/sso",
    component: _2deef8e9,
    name: "sso___et"
  }, {
    path: "/et/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___et"
  }, {
    path: "/et/tickets",
    component: _e447567e,
    name: "tickets___et"
  }, {
    path: "/fi/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___fi"
  }, {
    path: "/fi/app",
    component: _70ae5bfb,
    name: "app___fi"
  }, {
    path: "/fi/book",
    component: _4cc3bd02,
    name: "book___fi"
  }, {
    path: "/fi/favorites",
    component: _7e76309e,
    name: "favorites___fi"
  }, {
    path: "/fi/flights",
    component: _82010c46,
    name: "flights___fi"
  }, {
    path: "/fi/hotels",
    component: _7a9e8c18,
    name: "hotels___fi"
  }, {
    path: "/fi/login",
    component: _7d9c04c3,
    name: "login___fi"
  }, {
    path: "/fi/packages",
    component: _04542a83,
    name: "packages___fi"
  }, {
    path: "/fi/pay",
    component: _7111f702,
    name: "pay___fi"
  }, {
    path: "/fi/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___fi"
  }, {
    path: "/fi/profile",
    component: _fc4a92fa,
    name: "profile___fi"
  }, {
    path: "/fi/reservation",
    component: _22a47626,
    name: "reservation___fi"
  }, {
    path: "/fi/searches",
    component: _ce1ebd28,
    name: "searches___fi"
  }, {
    path: "/fi/sso",
    component: _2deef8e9,
    name: "sso___fi"
  }, {
    path: "/fi/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___fi"
  }, {
    path: "/fi/tickets",
    component: _e447567e,
    name: "tickets___fi"
  }, {
    path: "/fr/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___fr"
  }, {
    path: "/fr/app",
    component: _70ae5bfb,
    name: "app___fr"
  }, {
    path: "/fr/book",
    component: _4cc3bd02,
    name: "book___fr"
  }, {
    path: "/fr/favorites",
    component: _7e76309e,
    name: "favorites___fr"
  }, {
    path: "/fr/flights",
    component: _82010c46,
    name: "flights___fr"
  }, {
    path: "/fr/hotels",
    component: _7a9e8c18,
    name: "hotels___fr"
  }, {
    path: "/fr/login",
    component: _7d9c04c3,
    name: "login___fr"
  }, {
    path: "/fr/packages",
    component: _04542a83,
    name: "packages___fr"
  }, {
    path: "/fr/pay",
    component: _7111f702,
    name: "pay___fr"
  }, {
    path: "/fr/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/profile",
    component: _fc4a92fa,
    name: "profile___fr"
  }, {
    path: "/fr/reservation",
    component: _22a47626,
    name: "reservation___fr"
  }, {
    path: "/fr/searches",
    component: _ce1ebd28,
    name: "searches___fr"
  }, {
    path: "/fr/sso",
    component: _2deef8e9,
    name: "sso___fr"
  }, {
    path: "/fr/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___fr"
  }, {
    path: "/fr/tickets",
    component: _e447567e,
    name: "tickets___fr"
  }, {
    path: "/he/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___he"
  }, {
    path: "/he/app",
    component: _70ae5bfb,
    name: "app___he"
  }, {
    path: "/he/book",
    component: _4cc3bd02,
    name: "book___he"
  }, {
    path: "/he/favorites",
    component: _7e76309e,
    name: "favorites___he"
  }, {
    path: "/he/flights",
    component: _82010c46,
    name: "flights___he"
  }, {
    path: "/he/hotels",
    component: _7a9e8c18,
    name: "hotels___he"
  }, {
    path: "/he/login",
    component: _7d9c04c3,
    name: "login___he"
  }, {
    path: "/he/packages",
    component: _04542a83,
    name: "packages___he"
  }, {
    path: "/he/pay",
    component: _7111f702,
    name: "pay___he"
  }, {
    path: "/he/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___he"
  }, {
    path: "/he/profile",
    component: _fc4a92fa,
    name: "profile___he"
  }, {
    path: "/he/reservation",
    component: _22a47626,
    name: "reservation___he"
  }, {
    path: "/he/searches",
    component: _ce1ebd28,
    name: "searches___he"
  }, {
    path: "/he/sso",
    component: _2deef8e9,
    name: "sso___he"
  }, {
    path: "/he/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___he"
  }, {
    path: "/he/tickets",
    component: _e447567e,
    name: "tickets___he"
  }, {
    path: "/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___en"
  }, {
    path: "/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___en"
  }, {
    path: "/hr/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___hr"
  }, {
    path: "/hr/app",
    component: _70ae5bfb,
    name: "app___hr"
  }, {
    path: "/hr/book",
    component: _4cc3bd02,
    name: "book___hr"
  }, {
    path: "/hr/favorites",
    component: _7e76309e,
    name: "favorites___hr"
  }, {
    path: "/hr/flights",
    component: _82010c46,
    name: "flights___hr"
  }, {
    path: "/hr/hotels",
    component: _7a9e8c18,
    name: "hotels___hr"
  }, {
    path: "/hr/login",
    component: _7d9c04c3,
    name: "login___hr"
  }, {
    path: "/hr/packages",
    component: _04542a83,
    name: "packages___hr"
  }, {
    path: "/hr/pay",
    component: _7111f702,
    name: "pay___hr"
  }, {
    path: "/hr/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___hr"
  }, {
    path: "/hr/profile",
    component: _fc4a92fa,
    name: "profile___hr"
  }, {
    path: "/hr/reservation",
    component: _22a47626,
    name: "reservation___hr"
  }, {
    path: "/hr/searches",
    component: _ce1ebd28,
    name: "searches___hr"
  }, {
    path: "/hr/sso",
    component: _2deef8e9,
    name: "sso___hr"
  }, {
    path: "/hr/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___hr"
  }, {
    path: "/hr/tickets",
    component: _e447567e,
    name: "tickets___hr"
  }, {
    path: "/hu/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___hu"
  }, {
    path: "/hu/app",
    component: _70ae5bfb,
    name: "app___hu"
  }, {
    path: "/hu/book",
    component: _4cc3bd02,
    name: "book___hu"
  }, {
    path: "/hu/favorites",
    component: _7e76309e,
    name: "favorites___hu"
  }, {
    path: "/hu/flights",
    component: _82010c46,
    name: "flights___hu"
  }, {
    path: "/hu/hotels",
    component: _7a9e8c18,
    name: "hotels___hu"
  }, {
    path: "/hu/login",
    component: _7d9c04c3,
    name: "login___hu"
  }, {
    path: "/hu/packages",
    component: _04542a83,
    name: "packages___hu"
  }, {
    path: "/hu/pay",
    component: _7111f702,
    name: "pay___hu"
  }, {
    path: "/hu/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/profile",
    component: _fc4a92fa,
    name: "profile___hu"
  }, {
    path: "/hu/reservation",
    component: _22a47626,
    name: "reservation___hu"
  }, {
    path: "/hu/searches",
    component: _ce1ebd28,
    name: "searches___hu"
  }, {
    path: "/hu/sso",
    component: _2deef8e9,
    name: "sso___hu"
  }, {
    path: "/hu/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___hu"
  }, {
    path: "/hu/tickets",
    component: _e447567e,
    name: "tickets___hu"
  }, {
    path: "/id/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___id"
  }, {
    path: "/id/app",
    component: _70ae5bfb,
    name: "app___id"
  }, {
    path: "/id/book",
    component: _4cc3bd02,
    name: "book___id"
  }, {
    path: "/id/favorites",
    component: _7e76309e,
    name: "favorites___id"
  }, {
    path: "/id/flights",
    component: _82010c46,
    name: "flights___id"
  }, {
    path: "/id/hotels",
    component: _7a9e8c18,
    name: "hotels___id"
  }, {
    path: "/id/login",
    component: _7d9c04c3,
    name: "login___id"
  }, {
    path: "/id/packages",
    component: _04542a83,
    name: "packages___id"
  }, {
    path: "/id/pay",
    component: _7111f702,
    name: "pay___id"
  }, {
    path: "/id/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___id"
  }, {
    path: "/id/profile",
    component: _fc4a92fa,
    name: "profile___id"
  }, {
    path: "/id/reservation",
    component: _22a47626,
    name: "reservation___id"
  }, {
    path: "/id/searches",
    component: _ce1ebd28,
    name: "searches___id"
  }, {
    path: "/id/sso",
    component: _2deef8e9,
    name: "sso___id"
  }, {
    path: "/id/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___id"
  }, {
    path: "/id/tickets",
    component: _e447567e,
    name: "tickets___id"
  }, {
    path: "/is/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___is"
  }, {
    path: "/is/app",
    component: _70ae5bfb,
    name: "app___is"
  }, {
    path: "/is/book",
    component: _4cc3bd02,
    name: "book___is"
  }, {
    path: "/is/favorites",
    component: _7e76309e,
    name: "favorites___is"
  }, {
    path: "/is/flights",
    component: _82010c46,
    name: "flights___is"
  }, {
    path: "/is/hotels",
    component: _7a9e8c18,
    name: "hotels___is"
  }, {
    path: "/is/login",
    component: _7d9c04c3,
    name: "login___is"
  }, {
    path: "/is/packages",
    component: _04542a83,
    name: "packages___is"
  }, {
    path: "/is/pay",
    component: _7111f702,
    name: "pay___is"
  }, {
    path: "/is/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___is"
  }, {
    path: "/is/profile",
    component: _fc4a92fa,
    name: "profile___is"
  }, {
    path: "/is/reservation",
    component: _22a47626,
    name: "reservation___is"
  }, {
    path: "/is/searches",
    component: _ce1ebd28,
    name: "searches___is"
  }, {
    path: "/is/sso",
    component: _2deef8e9,
    name: "sso___is"
  }, {
    path: "/is/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___is"
  }, {
    path: "/is/tickets",
    component: _e447567e,
    name: "tickets___is"
  }, {
    path: "/it/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___it"
  }, {
    path: "/it/app",
    component: _70ae5bfb,
    name: "app___it"
  }, {
    path: "/it/book",
    component: _4cc3bd02,
    name: "book___it"
  }, {
    path: "/it/favorites",
    component: _7e76309e,
    name: "favorites___it"
  }, {
    path: "/it/flights",
    component: _82010c46,
    name: "flights___it"
  }, {
    path: "/it/hotels",
    component: _7a9e8c18,
    name: "hotels___it"
  }, {
    path: "/it/login",
    component: _7d9c04c3,
    name: "login___it"
  }, {
    path: "/it/packages",
    component: _04542a83,
    name: "packages___it"
  }, {
    path: "/it/pay",
    component: _7111f702,
    name: "pay___it"
  }, {
    path: "/it/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___it"
  }, {
    path: "/it/profile",
    component: _fc4a92fa,
    name: "profile___it"
  }, {
    path: "/it/reservation",
    component: _22a47626,
    name: "reservation___it"
  }, {
    path: "/it/searches",
    component: _ce1ebd28,
    name: "searches___it"
  }, {
    path: "/it/sso",
    component: _2deef8e9,
    name: "sso___it"
  }, {
    path: "/it/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___it"
  }, {
    path: "/it/tickets",
    component: _e447567e,
    name: "tickets___it"
  }, {
    path: "/ja/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___ja"
  }, {
    path: "/ja/app",
    component: _70ae5bfb,
    name: "app___ja"
  }, {
    path: "/ja/book",
    component: _4cc3bd02,
    name: "book___ja"
  }, {
    path: "/ja/favorites",
    component: _7e76309e,
    name: "favorites___ja"
  }, {
    path: "/ja/flights",
    component: _82010c46,
    name: "flights___ja"
  }, {
    path: "/ja/hotels",
    component: _7a9e8c18,
    name: "hotels___ja"
  }, {
    path: "/ja/login",
    component: _7d9c04c3,
    name: "login___ja"
  }, {
    path: "/ja/packages",
    component: _04542a83,
    name: "packages___ja"
  }, {
    path: "/ja/pay",
    component: _7111f702,
    name: "pay___ja"
  }, {
    path: "/ja/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___ja"
  }, {
    path: "/ja/profile",
    component: _fc4a92fa,
    name: "profile___ja"
  }, {
    path: "/ja/reservation",
    component: _22a47626,
    name: "reservation___ja"
  }, {
    path: "/ja/searches",
    component: _ce1ebd28,
    name: "searches___ja"
  }, {
    path: "/ja/sso",
    component: _2deef8e9,
    name: "sso___ja"
  }, {
    path: "/ja/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___ja"
  }, {
    path: "/ja/tickets",
    component: _e447567e,
    name: "tickets___ja"
  }, {
    path: "/ko/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___ko"
  }, {
    path: "/ko/app",
    component: _70ae5bfb,
    name: "app___ko"
  }, {
    path: "/ko/book",
    component: _4cc3bd02,
    name: "book___ko"
  }, {
    path: "/ko/favorites",
    component: _7e76309e,
    name: "favorites___ko"
  }, {
    path: "/ko/flights",
    component: _82010c46,
    name: "flights___ko"
  }, {
    path: "/ko/hotels",
    component: _7a9e8c18,
    name: "hotels___ko"
  }, {
    path: "/ko/login",
    component: _7d9c04c3,
    name: "login___ko"
  }, {
    path: "/ko/packages",
    component: _04542a83,
    name: "packages___ko"
  }, {
    path: "/ko/pay",
    component: _7111f702,
    name: "pay___ko"
  }, {
    path: "/ko/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___ko"
  }, {
    path: "/ko/profile",
    component: _fc4a92fa,
    name: "profile___ko"
  }, {
    path: "/ko/reservation",
    component: _22a47626,
    name: "reservation___ko"
  }, {
    path: "/ko/searches",
    component: _ce1ebd28,
    name: "searches___ko"
  }, {
    path: "/ko/sso",
    component: _2deef8e9,
    name: "sso___ko"
  }, {
    path: "/ko/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___ko"
  }, {
    path: "/ko/tickets",
    component: _e447567e,
    name: "tickets___ko"
  }, {
    path: "/lt/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___lt"
  }, {
    path: "/lt/app",
    component: _70ae5bfb,
    name: "app___lt"
  }, {
    path: "/lt/book",
    component: _4cc3bd02,
    name: "book___lt"
  }, {
    path: "/lt/favorites",
    component: _7e76309e,
    name: "favorites___lt"
  }, {
    path: "/lt/flights",
    component: _82010c46,
    name: "flights___lt"
  }, {
    path: "/lt/hotels",
    component: _7a9e8c18,
    name: "hotels___lt"
  }, {
    path: "/lt/login",
    component: _7d9c04c3,
    name: "login___lt"
  }, {
    path: "/lt/packages",
    component: _04542a83,
    name: "packages___lt"
  }, {
    path: "/lt/pay",
    component: _7111f702,
    name: "pay___lt"
  }, {
    path: "/lt/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___lt"
  }, {
    path: "/lt/profile",
    component: _fc4a92fa,
    name: "profile___lt"
  }, {
    path: "/lt/reservation",
    component: _22a47626,
    name: "reservation___lt"
  }, {
    path: "/lt/searches",
    component: _ce1ebd28,
    name: "searches___lt"
  }, {
    path: "/lt/sso",
    component: _2deef8e9,
    name: "sso___lt"
  }, {
    path: "/lt/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___lt"
  }, {
    path: "/lt/tickets",
    component: _e447567e,
    name: "tickets___lt"
  }, {
    path: "/ms/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___ms"
  }, {
    path: "/ms/app",
    component: _70ae5bfb,
    name: "app___ms"
  }, {
    path: "/ms/book",
    component: _4cc3bd02,
    name: "book___ms"
  }, {
    path: "/ms/favorites",
    component: _7e76309e,
    name: "favorites___ms"
  }, {
    path: "/ms/flights",
    component: _82010c46,
    name: "flights___ms"
  }, {
    path: "/ms/hotels",
    component: _7a9e8c18,
    name: "hotels___ms"
  }, {
    path: "/ms/login",
    component: _7d9c04c3,
    name: "login___ms"
  }, {
    path: "/ms/packages",
    component: _04542a83,
    name: "packages___ms"
  }, {
    path: "/ms/pay",
    component: _7111f702,
    name: "pay___ms"
  }, {
    path: "/ms/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___ms"
  }, {
    path: "/ms/profile",
    component: _fc4a92fa,
    name: "profile___ms"
  }, {
    path: "/ms/reservation",
    component: _22a47626,
    name: "reservation___ms"
  }, {
    path: "/ms/searches",
    component: _ce1ebd28,
    name: "searches___ms"
  }, {
    path: "/ms/sso",
    component: _2deef8e9,
    name: "sso___ms"
  }, {
    path: "/ms/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___ms"
  }, {
    path: "/ms/tickets",
    component: _e447567e,
    name: "tickets___ms"
  }, {
    path: "/nl/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___nl"
  }, {
    path: "/nl/app",
    component: _70ae5bfb,
    name: "app___nl"
  }, {
    path: "/nl/book",
    component: _4cc3bd02,
    name: "book___nl"
  }, {
    path: "/nl/favorites",
    component: _7e76309e,
    name: "favorites___nl"
  }, {
    path: "/nl/flights",
    component: _82010c46,
    name: "flights___nl"
  }, {
    path: "/nl/hotels",
    component: _7a9e8c18,
    name: "hotels___nl"
  }, {
    path: "/nl/login",
    component: _7d9c04c3,
    name: "login___nl"
  }, {
    path: "/nl/packages",
    component: _04542a83,
    name: "packages___nl"
  }, {
    path: "/nl/pay",
    component: _7111f702,
    name: "pay___nl"
  }, {
    path: "/nl/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___nl"
  }, {
    path: "/nl/profile",
    component: _fc4a92fa,
    name: "profile___nl"
  }, {
    path: "/nl/reservation",
    component: _22a47626,
    name: "reservation___nl"
  }, {
    path: "/nl/searches",
    component: _ce1ebd28,
    name: "searches___nl"
  }, {
    path: "/nl/sso",
    component: _2deef8e9,
    name: "sso___nl"
  }, {
    path: "/nl/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___nl"
  }, {
    path: "/nl/tickets",
    component: _e447567e,
    name: "tickets___nl"
  }, {
    path: "/pl/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___pl"
  }, {
    path: "/pl/app",
    component: _70ae5bfb,
    name: "app___pl"
  }, {
    path: "/pl/book",
    component: _4cc3bd02,
    name: "book___pl"
  }, {
    path: "/pl/favorites",
    component: _7e76309e,
    name: "favorites___pl"
  }, {
    path: "/pl/flights",
    component: _82010c46,
    name: "flights___pl"
  }, {
    path: "/pl/hotels",
    component: _7a9e8c18,
    name: "hotels___pl"
  }, {
    path: "/pl/login",
    component: _7d9c04c3,
    name: "login___pl"
  }, {
    path: "/pl/packages",
    component: _04542a83,
    name: "packages___pl"
  }, {
    path: "/pl/pay",
    component: _7111f702,
    name: "pay___pl"
  }, {
    path: "/pl/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___pl"
  }, {
    path: "/pl/profile",
    component: _fc4a92fa,
    name: "profile___pl"
  }, {
    path: "/pl/reservation",
    component: _22a47626,
    name: "reservation___pl"
  }, {
    path: "/pl/searches",
    component: _ce1ebd28,
    name: "searches___pl"
  }, {
    path: "/pl/sso",
    component: _2deef8e9,
    name: "sso___pl"
  }, {
    path: "/pl/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___pl"
  }, {
    path: "/pl/tickets",
    component: _e447567e,
    name: "tickets___pl"
  }, {
    path: "/pt-br/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___pt-br"
  }, {
    path: "/pt-br/app",
    component: _70ae5bfb,
    name: "app___pt-br"
  }, {
    path: "/pt-br/book",
    component: _4cc3bd02,
    name: "book___pt-br"
  }, {
    path: "/pt-br/favorites",
    component: _7e76309e,
    name: "favorites___pt-br"
  }, {
    path: "/pt-br/flights",
    component: _82010c46,
    name: "flights___pt-br"
  }, {
    path: "/pt-br/hotels",
    component: _7a9e8c18,
    name: "hotels___pt-br"
  }, {
    path: "/pt-br/login",
    component: _7d9c04c3,
    name: "login___pt-br"
  }, {
    path: "/pt-br/packages",
    component: _04542a83,
    name: "packages___pt-br"
  }, {
    path: "/pt-br/pay",
    component: _7111f702,
    name: "pay___pt-br"
  }, {
    path: "/pt-br/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___pt-br"
  }, {
    path: "/pt-br/profile",
    component: _fc4a92fa,
    name: "profile___pt-br"
  }, {
    path: "/pt-br/reservation",
    component: _22a47626,
    name: "reservation___pt-br"
  }, {
    path: "/pt-br/searches",
    component: _ce1ebd28,
    name: "searches___pt-br"
  }, {
    path: "/pt-br/sso",
    component: _2deef8e9,
    name: "sso___pt-br"
  }, {
    path: "/pt-br/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___pt-br"
  }, {
    path: "/pt-br/tickets",
    component: _e447567e,
    name: "tickets___pt-br"
  }, {
    path: "/pt/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___pt"
  }, {
    path: "/pt/app",
    component: _70ae5bfb,
    name: "app___pt"
  }, {
    path: "/pt/book",
    component: _4cc3bd02,
    name: "book___pt"
  }, {
    path: "/pt/favorites",
    component: _7e76309e,
    name: "favorites___pt"
  }, {
    path: "/pt/flights",
    component: _82010c46,
    name: "flights___pt"
  }, {
    path: "/pt/hotels",
    component: _7a9e8c18,
    name: "hotels___pt"
  }, {
    path: "/pt/login",
    component: _7d9c04c3,
    name: "login___pt"
  }, {
    path: "/pt/packages",
    component: _04542a83,
    name: "packages___pt"
  }, {
    path: "/pt/pay",
    component: _7111f702,
    name: "pay___pt"
  }, {
    path: "/pt/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___pt"
  }, {
    path: "/pt/profile",
    component: _fc4a92fa,
    name: "profile___pt"
  }, {
    path: "/pt/reservation",
    component: _22a47626,
    name: "reservation___pt"
  }, {
    path: "/pt/searches",
    component: _ce1ebd28,
    name: "searches___pt"
  }, {
    path: "/pt/sso",
    component: _2deef8e9,
    name: "sso___pt"
  }, {
    path: "/pt/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___pt"
  }, {
    path: "/pt/tickets",
    component: _e447567e,
    name: "tickets___pt"
  }, {
    path: "/ro/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___ro"
  }, {
    path: "/ro/app",
    component: _70ae5bfb,
    name: "app___ro"
  }, {
    path: "/ro/book",
    component: _4cc3bd02,
    name: "book___ro"
  }, {
    path: "/ro/favorites",
    component: _7e76309e,
    name: "favorites___ro"
  }, {
    path: "/ro/flights",
    component: _82010c46,
    name: "flights___ro"
  }, {
    path: "/ro/hotels",
    component: _7a9e8c18,
    name: "hotels___ro"
  }, {
    path: "/ro/login",
    component: _7d9c04c3,
    name: "login___ro"
  }, {
    path: "/ro/packages",
    component: _04542a83,
    name: "packages___ro"
  }, {
    path: "/ro/pay",
    component: _7111f702,
    name: "pay___ro"
  }, {
    path: "/ro/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___ro"
  }, {
    path: "/ro/profile",
    component: _fc4a92fa,
    name: "profile___ro"
  }, {
    path: "/ro/reservation",
    component: _22a47626,
    name: "reservation___ro"
  }, {
    path: "/ro/searches",
    component: _ce1ebd28,
    name: "searches___ro"
  }, {
    path: "/ro/sso",
    component: _2deef8e9,
    name: "sso___ro"
  }, {
    path: "/ro/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___ro"
  }, {
    path: "/ro/tickets",
    component: _e447567e,
    name: "tickets___ro"
  }, {
    path: "/ru/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___ru"
  }, {
    path: "/ru/app",
    component: _70ae5bfb,
    name: "app___ru"
  }, {
    path: "/ru/book",
    component: _4cc3bd02,
    name: "book___ru"
  }, {
    path: "/ru/favorites",
    component: _7e76309e,
    name: "favorites___ru"
  }, {
    path: "/ru/flights",
    component: _82010c46,
    name: "flights___ru"
  }, {
    path: "/ru/hotels",
    component: _7a9e8c18,
    name: "hotels___ru"
  }, {
    path: "/ru/login",
    component: _7d9c04c3,
    name: "login___ru"
  }, {
    path: "/ru/packages",
    component: _04542a83,
    name: "packages___ru"
  }, {
    path: "/ru/pay",
    component: _7111f702,
    name: "pay___ru"
  }, {
    path: "/ru/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___ru"
  }, {
    path: "/ru/profile",
    component: _fc4a92fa,
    name: "profile___ru"
  }, {
    path: "/ru/reservation",
    component: _22a47626,
    name: "reservation___ru"
  }, {
    path: "/ru/searches",
    component: _ce1ebd28,
    name: "searches___ru"
  }, {
    path: "/ru/sso",
    component: _2deef8e9,
    name: "sso___ru"
  }, {
    path: "/ru/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___ru"
  }, {
    path: "/ru/tickets",
    component: _e447567e,
    name: "tickets___ru"
  }, {
    path: "/sk/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___sk"
  }, {
    path: "/sk/app",
    component: _70ae5bfb,
    name: "app___sk"
  }, {
    path: "/sk/book",
    component: _4cc3bd02,
    name: "book___sk"
  }, {
    path: "/sk/favorites",
    component: _7e76309e,
    name: "favorites___sk"
  }, {
    path: "/sk/flights",
    component: _82010c46,
    name: "flights___sk"
  }, {
    path: "/sk/hotels",
    component: _7a9e8c18,
    name: "hotels___sk"
  }, {
    path: "/sk/login",
    component: _7d9c04c3,
    name: "login___sk"
  }, {
    path: "/sk/packages",
    component: _04542a83,
    name: "packages___sk"
  }, {
    path: "/sk/pay",
    component: _7111f702,
    name: "pay___sk"
  }, {
    path: "/sk/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___sk"
  }, {
    path: "/sk/profile",
    component: _fc4a92fa,
    name: "profile___sk"
  }, {
    path: "/sk/reservation",
    component: _22a47626,
    name: "reservation___sk"
  }, {
    path: "/sk/searches",
    component: _ce1ebd28,
    name: "searches___sk"
  }, {
    path: "/sk/sso",
    component: _2deef8e9,
    name: "sso___sk"
  }, {
    path: "/sk/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___sk"
  }, {
    path: "/sk/tickets",
    component: _e447567e,
    name: "tickets___sk"
  }, {
    path: "/sl/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___sl"
  }, {
    path: "/sl/app",
    component: _70ae5bfb,
    name: "app___sl"
  }, {
    path: "/sl/book",
    component: _4cc3bd02,
    name: "book___sl"
  }, {
    path: "/sl/favorites",
    component: _7e76309e,
    name: "favorites___sl"
  }, {
    path: "/sl/flights",
    component: _82010c46,
    name: "flights___sl"
  }, {
    path: "/sl/hotels",
    component: _7a9e8c18,
    name: "hotels___sl"
  }, {
    path: "/sl/login",
    component: _7d9c04c3,
    name: "login___sl"
  }, {
    path: "/sl/packages",
    component: _04542a83,
    name: "packages___sl"
  }, {
    path: "/sl/pay",
    component: _7111f702,
    name: "pay___sl"
  }, {
    path: "/sl/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___sl"
  }, {
    path: "/sl/profile",
    component: _fc4a92fa,
    name: "profile___sl"
  }, {
    path: "/sl/reservation",
    component: _22a47626,
    name: "reservation___sl"
  }, {
    path: "/sl/searches",
    component: _ce1ebd28,
    name: "searches___sl"
  }, {
    path: "/sl/sso",
    component: _2deef8e9,
    name: "sso___sl"
  }, {
    path: "/sl/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___sl"
  }, {
    path: "/sl/tickets",
    component: _e447567e,
    name: "tickets___sl"
  }, {
    path: "/sr/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___sr"
  }, {
    path: "/sr/app",
    component: _70ae5bfb,
    name: "app___sr"
  }, {
    path: "/sr/book",
    component: _4cc3bd02,
    name: "book___sr"
  }, {
    path: "/sr/favorites",
    component: _7e76309e,
    name: "favorites___sr"
  }, {
    path: "/sr/flights",
    component: _82010c46,
    name: "flights___sr"
  }, {
    path: "/sr/hotels",
    component: _7a9e8c18,
    name: "hotels___sr"
  }, {
    path: "/sr/login",
    component: _7d9c04c3,
    name: "login___sr"
  }, {
    path: "/sr/packages",
    component: _04542a83,
    name: "packages___sr"
  }, {
    path: "/sr/pay",
    component: _7111f702,
    name: "pay___sr"
  }, {
    path: "/sr/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___sr"
  }, {
    path: "/sr/profile",
    component: _fc4a92fa,
    name: "profile___sr"
  }, {
    path: "/sr/reservation",
    component: _22a47626,
    name: "reservation___sr"
  }, {
    path: "/sr/searches",
    component: _ce1ebd28,
    name: "searches___sr"
  }, {
    path: "/sr/sso",
    component: _2deef8e9,
    name: "sso___sr"
  }, {
    path: "/sr/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___sr"
  }, {
    path: "/sr/tickets",
    component: _e447567e,
    name: "tickets___sr"
  }, {
    path: "/sv/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___sv"
  }, {
    path: "/sv/app",
    component: _70ae5bfb,
    name: "app___sv"
  }, {
    path: "/sv/book",
    component: _4cc3bd02,
    name: "book___sv"
  }, {
    path: "/sv/favorites",
    component: _7e76309e,
    name: "favorites___sv"
  }, {
    path: "/sv/flights",
    component: _82010c46,
    name: "flights___sv"
  }, {
    path: "/sv/hotels",
    component: _7a9e8c18,
    name: "hotels___sv"
  }, {
    path: "/sv/login",
    component: _7d9c04c3,
    name: "login___sv"
  }, {
    path: "/sv/packages",
    component: _04542a83,
    name: "packages___sv"
  }, {
    path: "/sv/pay",
    component: _7111f702,
    name: "pay___sv"
  }, {
    path: "/sv/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___sv"
  }, {
    path: "/sv/profile",
    component: _fc4a92fa,
    name: "profile___sv"
  }, {
    path: "/sv/reservation",
    component: _22a47626,
    name: "reservation___sv"
  }, {
    path: "/sv/searches",
    component: _ce1ebd28,
    name: "searches___sv"
  }, {
    path: "/sv/sso",
    component: _2deef8e9,
    name: "sso___sv"
  }, {
    path: "/sv/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___sv"
  }, {
    path: "/sv/tickets",
    component: _e447567e,
    name: "tickets___sv"
  }, {
    path: "/th/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___th"
  }, {
    path: "/th/app",
    component: _70ae5bfb,
    name: "app___th"
  }, {
    path: "/th/book",
    component: _4cc3bd02,
    name: "book___th"
  }, {
    path: "/th/favorites",
    component: _7e76309e,
    name: "favorites___th"
  }, {
    path: "/th/flights",
    component: _82010c46,
    name: "flights___th"
  }, {
    path: "/th/hotels",
    component: _7a9e8c18,
    name: "hotels___th"
  }, {
    path: "/th/login",
    component: _7d9c04c3,
    name: "login___th"
  }, {
    path: "/th/packages",
    component: _04542a83,
    name: "packages___th"
  }, {
    path: "/th/pay",
    component: _7111f702,
    name: "pay___th"
  }, {
    path: "/th/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___th"
  }, {
    path: "/th/profile",
    component: _fc4a92fa,
    name: "profile___th"
  }, {
    path: "/th/reservation",
    component: _22a47626,
    name: "reservation___th"
  }, {
    path: "/th/searches",
    component: _ce1ebd28,
    name: "searches___th"
  }, {
    path: "/th/sso",
    component: _2deef8e9,
    name: "sso___th"
  }, {
    path: "/th/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___th"
  }, {
    path: "/th/tickets",
    component: _e447567e,
    name: "tickets___th"
  }, {
    path: "/tl-ph/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___tl-ph"
  }, {
    path: "/tl-ph/app",
    component: _70ae5bfb,
    name: "app___tl-ph"
  }, {
    path: "/tl-ph/book",
    component: _4cc3bd02,
    name: "book___tl-ph"
  }, {
    path: "/tl-ph/favorites",
    component: _7e76309e,
    name: "favorites___tl-ph"
  }, {
    path: "/tl-ph/flights",
    component: _82010c46,
    name: "flights___tl-ph"
  }, {
    path: "/tl-ph/hotels",
    component: _7a9e8c18,
    name: "hotels___tl-ph"
  }, {
    path: "/tl-ph/login",
    component: _7d9c04c3,
    name: "login___tl-ph"
  }, {
    path: "/tl-ph/packages",
    component: _04542a83,
    name: "packages___tl-ph"
  }, {
    path: "/tl-ph/pay",
    component: _7111f702,
    name: "pay___tl-ph"
  }, {
    path: "/tl-ph/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___tl-ph"
  }, {
    path: "/tl-ph/profile",
    component: _fc4a92fa,
    name: "profile___tl-ph"
  }, {
    path: "/tl-ph/reservation",
    component: _22a47626,
    name: "reservation___tl-ph"
  }, {
    path: "/tl-ph/searches",
    component: _ce1ebd28,
    name: "searches___tl-ph"
  }, {
    path: "/tl-ph/sso",
    component: _2deef8e9,
    name: "sso___tl-ph"
  }, {
    path: "/tl-ph/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___tl-ph"
  }, {
    path: "/tl-ph/tickets",
    component: _e447567e,
    name: "tickets___tl-ph"
  }, {
    path: "/tr/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___tr"
  }, {
    path: "/tr/app",
    component: _70ae5bfb,
    name: "app___tr"
  }, {
    path: "/tr/book",
    component: _4cc3bd02,
    name: "book___tr"
  }, {
    path: "/tr/favorites",
    component: _7e76309e,
    name: "favorites___tr"
  }, {
    path: "/tr/flights",
    component: _82010c46,
    name: "flights___tr"
  }, {
    path: "/tr/hotels",
    component: _7a9e8c18,
    name: "hotels___tr"
  }, {
    path: "/tr/login",
    component: _7d9c04c3,
    name: "login___tr"
  }, {
    path: "/tr/packages",
    component: _04542a83,
    name: "packages___tr"
  }, {
    path: "/tr/pay",
    component: _7111f702,
    name: "pay___tr"
  }, {
    path: "/tr/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___tr"
  }, {
    path: "/tr/profile",
    component: _fc4a92fa,
    name: "profile___tr"
  }, {
    path: "/tr/reservation",
    component: _22a47626,
    name: "reservation___tr"
  }, {
    path: "/tr/searches",
    component: _ce1ebd28,
    name: "searches___tr"
  }, {
    path: "/tr/sso",
    component: _2deef8e9,
    name: "sso___tr"
  }, {
    path: "/tr/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___tr"
  }, {
    path: "/tr/tickets",
    component: _e447567e,
    name: "tickets___tr"
  }, {
    path: "/uk/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___uk"
  }, {
    path: "/uk/app",
    component: _70ae5bfb,
    name: "app___uk"
  }, {
    path: "/uk/book",
    component: _4cc3bd02,
    name: "book___uk"
  }, {
    path: "/uk/favorites",
    component: _7e76309e,
    name: "favorites___uk"
  }, {
    path: "/uk/flights",
    component: _82010c46,
    name: "flights___uk"
  }, {
    path: "/uk/hotels",
    component: _7a9e8c18,
    name: "hotels___uk"
  }, {
    path: "/uk/login",
    component: _7d9c04c3,
    name: "login___uk"
  }, {
    path: "/uk/packages",
    component: _04542a83,
    name: "packages___uk"
  }, {
    path: "/uk/pay",
    component: _7111f702,
    name: "pay___uk"
  }, {
    path: "/uk/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___uk"
  }, {
    path: "/uk/profile",
    component: _fc4a92fa,
    name: "profile___uk"
  }, {
    path: "/uk/reservation",
    component: _22a47626,
    name: "reservation___uk"
  }, {
    path: "/uk/searches",
    component: _ce1ebd28,
    name: "searches___uk"
  }, {
    path: "/uk/sso",
    component: _2deef8e9,
    name: "sso___uk"
  }, {
    path: "/uk/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___uk"
  }, {
    path: "/uk/tickets",
    component: _e447567e,
    name: "tickets___uk"
  }, {
    path: "/vi/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___vi"
  }, {
    path: "/vi/app",
    component: _70ae5bfb,
    name: "app___vi"
  }, {
    path: "/vi/book",
    component: _4cc3bd02,
    name: "book___vi"
  }, {
    path: "/vi/favorites",
    component: _7e76309e,
    name: "favorites___vi"
  }, {
    path: "/vi/flights",
    component: _82010c46,
    name: "flights___vi"
  }, {
    path: "/vi/hotels",
    component: _7a9e8c18,
    name: "hotels___vi"
  }, {
    path: "/vi/login",
    component: _7d9c04c3,
    name: "login___vi"
  }, {
    path: "/vi/packages",
    component: _04542a83,
    name: "packages___vi"
  }, {
    path: "/vi/pay",
    component: _7111f702,
    name: "pay___vi"
  }, {
    path: "/vi/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___vi"
  }, {
    path: "/vi/profile",
    component: _fc4a92fa,
    name: "profile___vi"
  }, {
    path: "/vi/reservation",
    component: _22a47626,
    name: "reservation___vi"
  }, {
    path: "/vi/searches",
    component: _ce1ebd28,
    name: "searches___vi"
  }, {
    path: "/vi/sso",
    component: _2deef8e9,
    name: "sso___vi"
  }, {
    path: "/vi/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___vi"
  }, {
    path: "/vi/tickets",
    component: _e447567e,
    name: "tickets___vi"
  }, {
    path: "/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___en"
  }, {
    path: "/zh-cn/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___zh-cn"
  }, {
    path: "/zh-cn/app",
    component: _70ae5bfb,
    name: "app___zh-cn"
  }, {
    path: "/zh-cn/book",
    component: _4cc3bd02,
    name: "book___zh-cn"
  }, {
    path: "/zh-cn/favorites",
    component: _7e76309e,
    name: "favorites___zh-cn"
  }, {
    path: "/zh-cn/flights",
    component: _82010c46,
    name: "flights___zh-cn"
  }, {
    path: "/zh-cn/hotels",
    component: _7a9e8c18,
    name: "hotels___zh-cn"
  }, {
    path: "/zh-cn/login",
    component: _7d9c04c3,
    name: "login___zh-cn"
  }, {
    path: "/zh-cn/packages",
    component: _04542a83,
    name: "packages___zh-cn"
  }, {
    path: "/zh-cn/pay",
    component: _7111f702,
    name: "pay___zh-cn"
  }, {
    path: "/zh-cn/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___zh-cn"
  }, {
    path: "/zh-cn/profile",
    component: _fc4a92fa,
    name: "profile___zh-cn"
  }, {
    path: "/zh-cn/reservation",
    component: _22a47626,
    name: "reservation___zh-cn"
  }, {
    path: "/zh-cn/searches",
    component: _ce1ebd28,
    name: "searches___zh-cn"
  }, {
    path: "/zh-cn/sso",
    component: _2deef8e9,
    name: "sso___zh-cn"
  }, {
    path: "/zh-cn/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___zh-cn"
  }, {
    path: "/zh-cn/tickets",
    component: _e447567e,
    name: "tickets___zh-cn"
  }, {
    path: "/zh-tw/agent-terms-of-use",
    component: _55722f16,
    name: "agent-terms-of-use___zh-tw"
  }, {
    path: "/zh-tw/app",
    component: _70ae5bfb,
    name: "app___zh-tw"
  }, {
    path: "/zh-tw/book",
    component: _4cc3bd02,
    name: "book___zh-tw"
  }, {
    path: "/zh-tw/favorites",
    component: _7e76309e,
    name: "favorites___zh-tw"
  }, {
    path: "/zh-tw/flights",
    component: _82010c46,
    name: "flights___zh-tw"
  }, {
    path: "/zh-tw/hotels",
    component: _7a9e8c18,
    name: "hotels___zh-tw"
  }, {
    path: "/zh-tw/login",
    component: _7d9c04c3,
    name: "login___zh-tw"
  }, {
    path: "/zh-tw/packages",
    component: _04542a83,
    name: "packages___zh-tw"
  }, {
    path: "/zh-tw/pay",
    component: _7111f702,
    name: "pay___zh-tw"
  }, {
    path: "/zh-tw/privacy-policy",
    component: _7e9105a6,
    name: "privacy-policy___zh-tw"
  }, {
    path: "/zh-tw/profile",
    component: _fc4a92fa,
    name: "profile___zh-tw"
  }, {
    path: "/zh-tw/reservation",
    component: _22a47626,
    name: "reservation___zh-tw"
  }, {
    path: "/zh-tw/searches",
    component: _ce1ebd28,
    name: "searches___zh-tw"
  }, {
    path: "/zh-tw/sso",
    component: _2deef8e9,
    name: "sso___zh-tw"
  }, {
    path: "/zh-tw/terms-of-use",
    component: _4a16b12d,
    name: "terms-of-use___zh-tw"
  }, {
    path: "/zh-tw/tickets",
    component: _e447567e,
    name: "tickets___zh-tw"
  }, {
    path: "/ar/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___ar"
  }, {
    path: "/ar/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___ar"
  }, {
    path: "/ar/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___ar"
  }, {
    path: "/ar/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___ar"
  }, {
    path: "/ar/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___ar"
  }, {
    path: "/bg/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___bg"
  }, {
    path: "/bg/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___bg"
  }, {
    path: "/bg/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___bg"
  }, {
    path: "/bg/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___bg"
  }, {
    path: "/bg/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___bg"
  }, {
    path: "/ca/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___ca"
  }, {
    path: "/ca/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___ca"
  }, {
    path: "/ca/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___ca"
  }, {
    path: "/ca/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___ca"
  }, {
    path: "/ca/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___ca"
  }, {
    path: "/cs/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___cs"
  }, {
    path: "/cs/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___cs"
  }, {
    path: "/cs/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___cs"
  }, {
    path: "/cs/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___cs"
  }, {
    path: "/cs/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___cs"
  }, {
    path: "/da/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___da"
  }, {
    path: "/da/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___da"
  }, {
    path: "/da/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___da"
  }, {
    path: "/da/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___da"
  }, {
    path: "/da/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___da"
  }, {
    path: "/de/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___de"
  }, {
    path: "/de/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___de"
  }, {
    path: "/de/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___de"
  }, {
    path: "/de/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___de"
  }, {
    path: "/de/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___de"
  }, {
    path: "/el/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___el"
  }, {
    path: "/el/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___el"
  }, {
    path: "/el/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___el"
  }, {
    path: "/el/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___el"
  }, {
    path: "/el/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___el"
  }, {
    path: "/es/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___es"
  }, {
    path: "/es/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___es"
  }, {
    path: "/es/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___es"
  }, {
    path: "/es/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___es"
  }, {
    path: "/es/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___es"
  }, {
    path: "/et/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___et"
  }, {
    path: "/et/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___et"
  }, {
    path: "/et/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___et"
  }, {
    path: "/et/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___et"
  }, {
    path: "/et/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___et"
  }, {
    path: "/fi/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___fi"
  }, {
    path: "/fi/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___fi"
  }, {
    path: "/fi/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___fi"
  }, {
    path: "/fi/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___fi"
  }, {
    path: "/fi/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___fi"
  }, {
    path: "/fr/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___fr"
  }, {
    path: "/fr/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___fr"
  }, {
    path: "/fr/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___fr"
  }, {
    path: "/fr/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___fr"
  }, {
    path: "/fr/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___fr"
  }, {
    path: "/he/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___he"
  }, {
    path: "/he/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___he"
  }, {
    path: "/he/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___he"
  }, {
    path: "/he/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___he"
  }, {
    path: "/he/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___he"
  }, {
    path: "/hr/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___hr"
  }, {
    path: "/hr/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___hr"
  }, {
    path: "/hr/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___hr"
  }, {
    path: "/hr/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___hr"
  }, {
    path: "/hr/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___hr"
  }, {
    path: "/hu/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___hu"
  }, {
    path: "/hu/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___hu"
  }, {
    path: "/hu/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___hu"
  }, {
    path: "/hu/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___hu"
  }, {
    path: "/hu/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___hu"
  }, {
    path: "/id/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___id"
  }, {
    path: "/id/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___id"
  }, {
    path: "/id/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___id"
  }, {
    path: "/id/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___id"
  }, {
    path: "/id/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___id"
  }, {
    path: "/is/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___is"
  }, {
    path: "/is/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___is"
  }, {
    path: "/is/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___is"
  }, {
    path: "/is/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___is"
  }, {
    path: "/is/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___is"
  }, {
    path: "/it/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___it"
  }, {
    path: "/it/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___it"
  }, {
    path: "/it/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___it"
  }, {
    path: "/it/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___it"
  }, {
    path: "/it/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___it"
  }, {
    path: "/ja/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___ja"
  }, {
    path: "/ja/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___ja"
  }, {
    path: "/ja/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___ja"
  }, {
    path: "/ja/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___ja"
  }, {
    path: "/ja/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___ja"
  }, {
    path: "/ko/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___ko"
  }, {
    path: "/ko/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___ko"
  }, {
    path: "/ko/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___ko"
  }, {
    path: "/ko/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___ko"
  }, {
    path: "/ko/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___ko"
  }, {
    path: "/lt/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___lt"
  }, {
    path: "/lt/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___lt"
  }, {
    path: "/lt/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___lt"
  }, {
    path: "/lt/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___lt"
  }, {
    path: "/lt/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___lt"
  }, {
    path: "/ms/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___ms"
  }, {
    path: "/ms/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___ms"
  }, {
    path: "/ms/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___ms"
  }, {
    path: "/ms/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___ms"
  }, {
    path: "/ms/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___ms"
  }, {
    path: "/nl/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___nl"
  }, {
    path: "/nl/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___nl"
  }, {
    path: "/nl/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___nl"
  }, {
    path: "/nl/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___nl"
  }, {
    path: "/nl/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___nl"
  }, {
    path: "/pl/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___pl"
  }, {
    path: "/pl/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___pl"
  }, {
    path: "/pl/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___pl"
  }, {
    path: "/pl/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___pl"
  }, {
    path: "/pl/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___pl"
  }, {
    path: "/pt-br/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___pt-br"
  }, {
    path: "/pt-br/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___pt-br"
  }, {
    path: "/pt-br/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___pt-br"
  }, {
    path: "/pt-br/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___pt-br"
  }, {
    path: "/pt-br/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___pt-br"
  }, {
    path: "/pt/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___pt"
  }, {
    path: "/pt/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___pt"
  }, {
    path: "/pt/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___pt"
  }, {
    path: "/pt/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___pt"
  }, {
    path: "/pt/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___pt"
  }, {
    path: "/ro/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___ro"
  }, {
    path: "/ro/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___ro"
  }, {
    path: "/ro/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___ro"
  }, {
    path: "/ro/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___ro"
  }, {
    path: "/ro/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___ro"
  }, {
    path: "/ru/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___ru"
  }, {
    path: "/ru/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___ru"
  }, {
    path: "/ru/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___ru"
  }, {
    path: "/ru/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___ru"
  }, {
    path: "/ru/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___ru"
  }, {
    path: "/sk/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___sk"
  }, {
    path: "/sk/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___sk"
  }, {
    path: "/sk/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___sk"
  }, {
    path: "/sk/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___sk"
  }, {
    path: "/sk/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___sk"
  }, {
    path: "/sl/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___sl"
  }, {
    path: "/sl/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___sl"
  }, {
    path: "/sl/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___sl"
  }, {
    path: "/sl/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___sl"
  }, {
    path: "/sl/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___sl"
  }, {
    path: "/sr/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___sr"
  }, {
    path: "/sr/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___sr"
  }, {
    path: "/sr/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___sr"
  }, {
    path: "/sr/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___sr"
  }, {
    path: "/sr/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___sr"
  }, {
    path: "/sv/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___sv"
  }, {
    path: "/sv/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___sv"
  }, {
    path: "/sv/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___sv"
  }, {
    path: "/sv/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___sv"
  }, {
    path: "/sv/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___sv"
  }, {
    path: "/th/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___th"
  }, {
    path: "/th/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___th"
  }, {
    path: "/th/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___th"
  }, {
    path: "/th/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___th"
  }, {
    path: "/th/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___th"
  }, {
    path: "/tl-ph/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___tl-ph"
  }, {
    path: "/tl-ph/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___tl-ph"
  }, {
    path: "/tl-ph/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___tl-ph"
  }, {
    path: "/tl-ph/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___tl-ph"
  }, {
    path: "/tl-ph/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___tl-ph"
  }, {
    path: "/tr/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___tr"
  }, {
    path: "/tr/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___tr"
  }, {
    path: "/tr/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___tr"
  }, {
    path: "/tr/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___tr"
  }, {
    path: "/tr/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___tr"
  }, {
    path: "/uk/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___uk"
  }, {
    path: "/uk/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___uk"
  }, {
    path: "/uk/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___uk"
  }, {
    path: "/uk/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___uk"
  }, {
    path: "/uk/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___uk"
  }, {
    path: "/vi/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___vi"
  }, {
    path: "/vi/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___vi"
  }, {
    path: "/vi/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___vi"
  }, {
    path: "/vi/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___vi"
  }, {
    path: "/vi/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___vi"
  }, {
    path: "/zh-cn/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___zh-cn"
  }, {
    path: "/zh-cn/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___zh-cn"
  }, {
    path: "/zh-cn/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___zh-cn"
  }, {
    path: "/zh-cn/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___zh-cn"
  }, {
    path: "/zh-cn/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___zh-cn"
  }, {
    path: "/zh-tw/account/reservations",
    component: _2eba8ca2,
    name: "account-reservations___zh-tw"
  }, {
    path: "/zh-tw/dashboard/bookings",
    component: _19a7f2a4,
    name: "dashboard-bookings___zh-tw"
  }, {
    path: "/zh-tw/hotels/israel",
    component: _7c1d91ea,
    name: "hotels-israel___zh-tw"
  }, {
    path: "/zh-tw/hotels/search",
    component: _f61c5f5c,
    name: "hotels-search___zh-tw"
  }, {
    path: "/zh-tw/widgets/search",
    component: _6f7cfe9e,
    name: "widgets-search___zh-tw"
  }, {
    path: "/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___en"
  }, {
    path: "/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___en"
  }, {
    path: "/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___en"
  }, {
    path: "/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___en"
  }, {
    path: "/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___en"
  }, {
    path: "/ar/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___ar"
  }, {
    path: "/ar/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___ar"
  }, {
    path: "/ar/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___ar"
  }, {
    path: "/ar/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___ar"
  }, {
    path: "/ar/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___ar"
  }, {
    path: "/bg/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___bg"
  }, {
    path: "/bg/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___bg"
  }, {
    path: "/bg/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___bg"
  }, {
    path: "/bg/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___bg"
  }, {
    path: "/bg/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___bg"
  }, {
    path: "/ca/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___ca"
  }, {
    path: "/ca/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___ca"
  }, {
    path: "/ca/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___ca"
  }, {
    path: "/ca/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___ca"
  }, {
    path: "/ca/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___ca"
  }, {
    path: "/cs/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___cs"
  }, {
    path: "/cs/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___cs"
  }, {
    path: "/cs/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___cs"
  }, {
    path: "/cs/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___cs"
  }, {
    path: "/cs/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___cs"
  }, {
    path: "/da/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___da"
  }, {
    path: "/da/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___da"
  }, {
    path: "/da/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___da"
  }, {
    path: "/da/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___da"
  }, {
    path: "/da/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___da"
  }, {
    path: "/de/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___de"
  }, {
    path: "/de/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___de"
  }, {
    path: "/de/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___de"
  }, {
    path: "/de/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___de"
  }, {
    path: "/de/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___de"
  }, {
    path: "/el/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___el"
  }, {
    path: "/el/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___el"
  }, {
    path: "/el/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___el"
  }, {
    path: "/el/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___el"
  }, {
    path: "/el/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___el"
  }, {
    path: "/es/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___es"
  }, {
    path: "/es/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___es"
  }, {
    path: "/es/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___es"
  }, {
    path: "/es/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___es"
  }, {
    path: "/es/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___es"
  }, {
    path: "/et/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___et"
  }, {
    path: "/et/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___et"
  }, {
    path: "/et/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___et"
  }, {
    path: "/et/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___et"
  }, {
    path: "/et/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___et"
  }, {
    path: "/fi/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___fi"
  }, {
    path: "/fi/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___fi"
  }, {
    path: "/fi/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___fi"
  }, {
    path: "/fi/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___fi"
  }, {
    path: "/fi/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___fi"
  }, {
    path: "/fr/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___fr"
  }, {
    path: "/fr/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___fr"
  }, {
    path: "/fr/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___fr"
  }, {
    path: "/fr/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___fr"
  }, {
    path: "/fr/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___fr"
  }, {
    path: "/he/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___he"
  }, {
    path: "/he/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___he"
  }, {
    path: "/he/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___he"
  }, {
    path: "/he/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___he"
  }, {
    path: "/he/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___he"
  }, {
    path: "/hr/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___hr"
  }, {
    path: "/hr/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___hr"
  }, {
    path: "/hr/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___hr"
  }, {
    path: "/hr/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___hr"
  }, {
    path: "/hr/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___hr"
  }, {
    path: "/hu/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___hu"
  }, {
    path: "/hu/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___hu"
  }, {
    path: "/hu/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___hu"
  }, {
    path: "/hu/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___hu"
  }, {
    path: "/hu/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___hu"
  }, {
    path: "/id/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___id"
  }, {
    path: "/id/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___id"
  }, {
    path: "/id/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___id"
  }, {
    path: "/id/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___id"
  }, {
    path: "/id/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___id"
  }, {
    path: "/is/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___is"
  }, {
    path: "/is/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___is"
  }, {
    path: "/is/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___is"
  }, {
    path: "/is/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___is"
  }, {
    path: "/is/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___is"
  }, {
    path: "/it/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___it"
  }, {
    path: "/it/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___it"
  }, {
    path: "/it/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___it"
  }, {
    path: "/it/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___it"
  }, {
    path: "/it/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___it"
  }, {
    path: "/ja/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___ja"
  }, {
    path: "/ja/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___ja"
  }, {
    path: "/ja/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___ja"
  }, {
    path: "/ja/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___ja"
  }, {
    path: "/ja/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___ja"
  }, {
    path: "/ko/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___ko"
  }, {
    path: "/ko/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___ko"
  }, {
    path: "/ko/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___ko"
  }, {
    path: "/ko/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___ko"
  }, {
    path: "/ko/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___ko"
  }, {
    path: "/lt/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___lt"
  }, {
    path: "/lt/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___lt"
  }, {
    path: "/lt/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___lt"
  }, {
    path: "/lt/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___lt"
  }, {
    path: "/lt/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___lt"
  }, {
    path: "/ms/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___ms"
  }, {
    path: "/ms/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___ms"
  }, {
    path: "/ms/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___ms"
  }, {
    path: "/ms/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___ms"
  }, {
    path: "/ms/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___ms"
  }, {
    path: "/nl/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___nl"
  }, {
    path: "/nl/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___nl"
  }, {
    path: "/nl/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___nl"
  }, {
    path: "/nl/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___nl"
  }, {
    path: "/nl/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___nl"
  }, {
    path: "/pl/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___pl"
  }, {
    path: "/pl/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___pl"
  }, {
    path: "/pl/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___pl"
  }, {
    path: "/pl/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___pl"
  }, {
    path: "/pl/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___pl"
  }, {
    path: "/pt-br/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___pt-br"
  }, {
    path: "/pt-br/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___pt-br"
  }, {
    path: "/pt-br/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___pt-br"
  }, {
    path: "/pt-br/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___pt-br"
  }, {
    path: "/pt-br/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___pt-br"
  }, {
    path: "/pt/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___pt"
  }, {
    path: "/pt/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___pt"
  }, {
    path: "/pt/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___pt"
  }, {
    path: "/pt/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___pt"
  }, {
    path: "/pt/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___pt"
  }, {
    path: "/ro/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___ro"
  }, {
    path: "/ro/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___ro"
  }, {
    path: "/ro/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___ro"
  }, {
    path: "/ro/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___ro"
  }, {
    path: "/ro/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___ro"
  }, {
    path: "/ru/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___ru"
  }, {
    path: "/ru/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___ru"
  }, {
    path: "/ru/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___ru"
  }, {
    path: "/ru/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___ru"
  }, {
    path: "/ru/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___ru"
  }, {
    path: "/sk/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___sk"
  }, {
    path: "/sk/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___sk"
  }, {
    path: "/sk/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___sk"
  }, {
    path: "/sk/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___sk"
  }, {
    path: "/sk/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___sk"
  }, {
    path: "/sl/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___sl"
  }, {
    path: "/sl/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___sl"
  }, {
    path: "/sl/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___sl"
  }, {
    path: "/sl/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___sl"
  }, {
    path: "/sl/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___sl"
  }, {
    path: "/sr/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___sr"
  }, {
    path: "/sr/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___sr"
  }, {
    path: "/sr/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___sr"
  }, {
    path: "/sr/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___sr"
  }, {
    path: "/sr/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___sr"
  }, {
    path: "/sv/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___sv"
  }, {
    path: "/sv/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___sv"
  }, {
    path: "/sv/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___sv"
  }, {
    path: "/sv/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___sv"
  }, {
    path: "/sv/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___sv"
  }, {
    path: "/th/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___th"
  }, {
    path: "/th/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___th"
  }, {
    path: "/th/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___th"
  }, {
    path: "/th/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___th"
  }, {
    path: "/th/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___th"
  }, {
    path: "/tl-ph/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___tl-ph"
  }, {
    path: "/tl-ph/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___tl-ph"
  }, {
    path: "/tl-ph/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___tl-ph"
  }, {
    path: "/tl-ph/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___tl-ph"
  }, {
    path: "/tl-ph/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___tl-ph"
  }, {
    path: "/tr/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___tr"
  }, {
    path: "/tr/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___tr"
  }, {
    path: "/tr/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___tr"
  }, {
    path: "/tr/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___tr"
  }, {
    path: "/tr/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___tr"
  }, {
    path: "/uk/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___uk"
  }, {
    path: "/uk/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___uk"
  }, {
    path: "/uk/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___uk"
  }, {
    path: "/uk/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___uk"
  }, {
    path: "/uk/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___uk"
  }, {
    path: "/vi/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___vi"
  }, {
    path: "/vi/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___vi"
  }, {
    path: "/vi/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___vi"
  }, {
    path: "/vi/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___vi"
  }, {
    path: "/vi/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___vi"
  }, {
    path: "/zh-cn/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___zh-cn"
  }, {
    path: "/zh-cn/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___zh-cn"
  }, {
    path: "/zh-cn/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___zh-cn"
  }, {
    path: "/zh-cn/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___zh-cn"
  }, {
    path: "/zh-cn/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___zh-cn"
  }, {
    path: "/zh-tw/hotels/places/ae/dubai",
    component: _54a258d7,
    name: "hotels-places-ae-dubai___zh-tw"
  }, {
    path: "/zh-tw/hotels/places/de/berlin",
    component: _b496eed2,
    name: "hotels-places-de-berlin___zh-tw"
  }, {
    path: "/zh-tw/hotels/places/es/barcelona",
    component: _be3b449a,
    name: "hotels-places-es-barcelona___zh-tw"
  }, {
    path: "/zh-tw/hotels/places/mx/cancun",
    component: _52d7c612,
    name: "hotels-places-mx-cancun___zh-tw"
  }, {
    path: "/zh-tw/hotels/places/nl/amsterdam",
    component: _6975cdee,
    name: "hotels-places-nl-amsterdam___zh-tw"
  }, {
    path: "/ar/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___ar"
  }, {
    path: "/ar/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___ar"
  }, {
    path: "/ar/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___ar"
  }, {
    path: "/bg/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___bg"
  }, {
    path: "/bg/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___bg"
  }, {
    path: "/bg/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___bg"
  }, {
    path: "/ca/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___ca"
  }, {
    path: "/ca/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___ca"
  }, {
    path: "/ca/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___ca"
  }, {
    path: "/cs/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___cs"
  }, {
    path: "/cs/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___cs"
  }, {
    path: "/cs/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___cs"
  }, {
    path: "/da/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___da"
  }, {
    path: "/da/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___da"
  }, {
    path: "/da/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___da"
  }, {
    path: "/de/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___de"
  }, {
    path: "/de/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___de"
  }, {
    path: "/de/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___de"
  }, {
    path: "/el/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___el"
  }, {
    path: "/el/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___el"
  }, {
    path: "/el/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___el"
  }, {
    path: "/es/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___es"
  }, {
    path: "/es/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___es"
  }, {
    path: "/es/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___es"
  }, {
    path: "/et/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___et"
  }, {
    path: "/et/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___et"
  }, {
    path: "/et/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___et"
  }, {
    path: "/fi/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___fi"
  }, {
    path: "/fi/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___fi"
  }, {
    path: "/fi/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___fi"
  }, {
    path: "/fr/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___fr"
  }, {
    path: "/fr/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___fr"
  }, {
    path: "/fr/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___fr"
  }, {
    path: "/he/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___he"
  }, {
    path: "/he/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___he"
  }, {
    path: "/he/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___he"
  }, {
    path: "/hr/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___hr"
  }, {
    path: "/hr/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___hr"
  }, {
    path: "/hr/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___hr"
  }, {
    path: "/hu/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___hu"
  }, {
    path: "/hu/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___hu"
  }, {
    path: "/hu/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___hu"
  }, {
    path: "/id/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___id"
  }, {
    path: "/id/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___id"
  }, {
    path: "/id/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___id"
  }, {
    path: "/is/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___is"
  }, {
    path: "/is/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___is"
  }, {
    path: "/is/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___is"
  }, {
    path: "/it/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___it"
  }, {
    path: "/it/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___it"
  }, {
    path: "/it/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___it"
  }, {
    path: "/ja/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___ja"
  }, {
    path: "/ja/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___ja"
  }, {
    path: "/ja/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___ja"
  }, {
    path: "/ko/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___ko"
  }, {
    path: "/ko/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___ko"
  }, {
    path: "/ko/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___ko"
  }, {
    path: "/lt/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___lt"
  }, {
    path: "/lt/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___lt"
  }, {
    path: "/lt/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___lt"
  }, {
    path: "/ms/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___ms"
  }, {
    path: "/ms/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___ms"
  }, {
    path: "/ms/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___ms"
  }, {
    path: "/nl/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___nl"
  }, {
    path: "/nl/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___nl"
  }, {
    path: "/nl/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___nl"
  }, {
    path: "/pl/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___pl"
  }, {
    path: "/pl/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___pl"
  }, {
    path: "/pl/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___pl"
  }, {
    path: "/pt-br/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___pt-br"
  }, {
    path: "/pt-br/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___pt-br"
  }, {
    path: "/pt-br/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___pt-br"
  }, {
    path: "/pt/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___pt"
  }, {
    path: "/pt/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___pt"
  }, {
    path: "/pt/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___pt"
  }, {
    path: "/ro/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___ro"
  }, {
    path: "/ro/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___ro"
  }, {
    path: "/ro/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___ro"
  }, {
    path: "/ru/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___ru"
  }, {
    path: "/ru/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___ru"
  }, {
    path: "/ru/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___ru"
  }, {
    path: "/sk/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___sk"
  }, {
    path: "/sk/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___sk"
  }, {
    path: "/sk/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___sk"
  }, {
    path: "/sl/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___sl"
  }, {
    path: "/sl/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___sl"
  }, {
    path: "/sl/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___sl"
  }, {
    path: "/sr/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___sr"
  }, {
    path: "/sr/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___sr"
  }, {
    path: "/sr/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___sr"
  }, {
    path: "/sv/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___sv"
  }, {
    path: "/sv/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___sv"
  }, {
    path: "/sv/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___sv"
  }, {
    path: "/th/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___th"
  }, {
    path: "/th/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___th"
  }, {
    path: "/th/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___th"
  }, {
    path: "/tl-ph/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___tl-ph"
  }, {
    path: "/tl-ph/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___tl-ph"
  }, {
    path: "/tl-ph/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___tl-ph"
  }, {
    path: "/tr/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___tr"
  }, {
    path: "/tr/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___tr"
  }, {
    path: "/tr/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___tr"
  }, {
    path: "/uk/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___uk"
  }, {
    path: "/uk/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___uk"
  }, {
    path: "/uk/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___uk"
  }, {
    path: "/vi/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___vi"
  }, {
    path: "/vi/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___vi"
  }, {
    path: "/vi/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___vi"
  }, {
    path: "/zh-cn/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___zh-cn"
  }, {
    path: "/zh-cn/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___zh-cn"
  }, {
    path: "/zh-cn/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___zh-cn"
  }, {
    path: "/zh-tw/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___zh-tw"
  }, {
    path: "/zh-tw/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___zh-tw"
  }, {
    path: "/zh-tw/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___zh-tw"
  }, {
    path: "/ar/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___ar"
  }, {
    path: "/bg/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___bg"
  }, {
    path: "/ca/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___ca"
  }, {
    path: "/cs/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___cs"
  }, {
    path: "/da/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___da"
  }, {
    path: "/de/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___de"
  }, {
    path: "/el/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___el"
  }, {
    path: "/es/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___es"
  }, {
    path: "/et/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___et"
  }, {
    path: "/fi/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___fi"
  }, {
    path: "/fr/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___fr"
  }, {
    path: "/he/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___he"
  }, {
    path: "/hr/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___hr"
  }, {
    path: "/hu/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___hu"
  }, {
    path: "/id/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___id"
  }, {
    path: "/is/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___is"
  }, {
    path: "/it/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___it"
  }, {
    path: "/ja/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___ja"
  }, {
    path: "/ko/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___ko"
  }, {
    path: "/lt/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___lt"
  }, {
    path: "/ms/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___ms"
  }, {
    path: "/nl/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___nl"
  }, {
    path: "/pl/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___pl"
  }, {
    path: "/pt-br/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___pt-br"
  }, {
    path: "/pt/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___pt"
  }, {
    path: "/ro/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___ro"
  }, {
    path: "/ru/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___ru"
  }, {
    path: "/sk/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___sk"
  }, {
    path: "/sl/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___sl"
  }, {
    path: "/sr/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___sr"
  }, {
    path: "/sv/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___sv"
  }, {
    path: "/th/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___th"
  }, {
    path: "/tl-ph/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___tl-ph"
  }, {
    path: "/tr/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___tr"
  }, {
    path: "/uk/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___uk"
  }, {
    path: "/vi/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___vi"
  }, {
    path: "/zh-cn/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___zh-cn"
  }, {
    path: "/zh-tw/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___zh-tw"
  }, {
    path: "/ar/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___ar"
  }, {
    path: "/ar/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___ar"
  }, {
    path: "/bg/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___bg"
  }, {
    path: "/bg/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___bg"
  }, {
    path: "/ca/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___ca"
  }, {
    path: "/ca/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___ca"
  }, {
    path: "/cs/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___cs"
  }, {
    path: "/cs/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___cs"
  }, {
    path: "/da/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___da"
  }, {
    path: "/da/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___da"
  }, {
    path: "/de/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___de"
  }, {
    path: "/de/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___de"
  }, {
    path: "/el/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___el"
  }, {
    path: "/el/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___el"
  }, {
    path: "/es/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___es"
  }, {
    path: "/es/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___es"
  }, {
    path: "/et/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___et"
  }, {
    path: "/et/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___et"
  }, {
    path: "/fi/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___fi"
  }, {
    path: "/fi/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___fi"
  }, {
    path: "/fr/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___fr"
  }, {
    path: "/fr/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___fr"
  }, {
    path: "/he/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___he"
  }, {
    path: "/he/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___he"
  }, {
    path: "/hr/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___hr"
  }, {
    path: "/hr/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___hr"
  }, {
    path: "/hu/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___hu"
  }, {
    path: "/hu/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___hu"
  }, {
    path: "/id/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___id"
  }, {
    path: "/id/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___id"
  }, {
    path: "/is/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___is"
  }, {
    path: "/is/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___is"
  }, {
    path: "/it/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___it"
  }, {
    path: "/it/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___it"
  }, {
    path: "/ja/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___ja"
  }, {
    path: "/ja/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___ja"
  }, {
    path: "/ko/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___ko"
  }, {
    path: "/ko/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___ko"
  }, {
    path: "/lt/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___lt"
  }, {
    path: "/lt/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___lt"
  }, {
    path: "/ms/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___ms"
  }, {
    path: "/ms/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___ms"
  }, {
    path: "/nl/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___nl"
  }, {
    path: "/nl/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___nl"
  }, {
    path: "/pl/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___pl"
  }, {
    path: "/pl/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___pl"
  }, {
    path: "/pt-br/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___pt-br"
  }, {
    path: "/pt-br/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___pt-br"
  }, {
    path: "/pt/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___pt"
  }, {
    path: "/pt/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___pt"
  }, {
    path: "/ro/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___ro"
  }, {
    path: "/ro/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___ro"
  }, {
    path: "/ru/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___ru"
  }, {
    path: "/ru/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___ru"
  }, {
    path: "/sk/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___sk"
  }, {
    path: "/sk/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___sk"
  }, {
    path: "/sl/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___sl"
  }, {
    path: "/sl/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___sl"
  }, {
    path: "/sr/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___sr"
  }, {
    path: "/sr/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___sr"
  }, {
    path: "/sv/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___sv"
  }, {
    path: "/sv/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___sv"
  }, {
    path: "/th/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___th"
  }, {
    path: "/th/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___th"
  }, {
    path: "/tl-ph/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___tl-ph"
  }, {
    path: "/tl-ph/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___tl-ph"
  }, {
    path: "/tr/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___tr"
  }, {
    path: "/tr/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___tr"
  }, {
    path: "/uk/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___uk"
  }, {
    path: "/uk/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___uk"
  }, {
    path: "/vi/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___vi"
  }, {
    path: "/vi/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___vi"
  }, {
    path: "/zh-cn/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___zh-cn"
  }, {
    path: "/zh-cn/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___zh-cn"
  }, {
    path: "/zh-tw/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___zh-tw"
  }, {
    path: "/zh-tw/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___zh-tw"
  }, {
    path: "/ar/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___ar"
  }, {
    path: "/bg/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___bg"
  }, {
    path: "/ca/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___ca"
  }, {
    path: "/cs/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___cs"
  }, {
    path: "/da/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___da"
  }, {
    path: "/de/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___de"
  }, {
    path: "/el/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___el"
  }, {
    path: "/es/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___es"
  }, {
    path: "/et/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___et"
  }, {
    path: "/fi/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___fi"
  }, {
    path: "/fr/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___fr"
  }, {
    path: "/he/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___he"
  }, {
    path: "/hr/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___hr"
  }, {
    path: "/hu/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___hu"
  }, {
    path: "/id/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___id"
  }, {
    path: "/is/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___is"
  }, {
    path: "/it/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___it"
  }, {
    path: "/ja/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___ja"
  }, {
    path: "/ko/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___ko"
  }, {
    path: "/lt/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___lt"
  }, {
    path: "/ms/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___ms"
  }, {
    path: "/nl/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___nl"
  }, {
    path: "/pl/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___pl"
  }, {
    path: "/pt-br/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___pt-br"
  }, {
    path: "/pt/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___pt"
  }, {
    path: "/ro/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___ro"
  }, {
    path: "/ru/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___ru"
  }, {
    path: "/sk/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___sk"
  }, {
    path: "/sl/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___sl"
  }, {
    path: "/sr/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___sr"
  }, {
    path: "/sv/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___sv"
  }, {
    path: "/th/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___th"
  }, {
    path: "/tl-ph/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___tl-ph"
  }, {
    path: "/tr/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___tr"
  }, {
    path: "/uk/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___uk"
  }, {
    path: "/vi/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___vi"
  }, {
    path: "/zh-cn/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___zh-cn"
  }, {
    path: "/zh-tw/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___zh-tw"
  }, {
    path: "/ar/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___ar"
  }, {
    path: "/bg/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___bg"
  }, {
    path: "/ca/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___ca"
  }, {
    path: "/cs/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___cs"
  }, {
    path: "/da/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___da"
  }, {
    path: "/de/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___de"
  }, {
    path: "/el/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___el"
  }, {
    path: "/es/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___es"
  }, {
    path: "/et/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___et"
  }, {
    path: "/fi/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___fi"
  }, {
    path: "/fr/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___fr"
  }, {
    path: "/he/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___he"
  }, {
    path: "/hr/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___hr"
  }, {
    path: "/hu/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___hu"
  }, {
    path: "/id/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___id"
  }, {
    path: "/is/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___is"
  }, {
    path: "/it/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___it"
  }, {
    path: "/ja/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___ja"
  }, {
    path: "/ko/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___ko"
  }, {
    path: "/lt/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___lt"
  }, {
    path: "/ms/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___ms"
  }, {
    path: "/nl/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___nl"
  }, {
    path: "/pl/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___pl"
  }, {
    path: "/pt-br/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___pt-br"
  }, {
    path: "/pt/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___pt"
  }, {
    path: "/ro/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___ro"
  }, {
    path: "/ru/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___ru"
  }, {
    path: "/sk/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___sk"
  }, {
    path: "/sl/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___sl"
  }, {
    path: "/sr/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___sr"
  }, {
    path: "/sv/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___sv"
  }, {
    path: "/th/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___th"
  }, {
    path: "/tl-ph/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___tl-ph"
  }, {
    path: "/tr/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___tr"
  }, {
    path: "/uk/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___uk"
  }, {
    path: "/vi/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___vi"
  }, {
    path: "/zh-cn/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___zh-cn"
  }, {
    path: "/zh-tw/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___zh-tw"
  }, {
    path: "/account/reservations/:uuid",
    component: _778c272c,
    name: "account-reservations-uuid___en"
  }, {
    path: "/dashboard/bookings/:uuid",
    component: _eea93314,
    name: "dashboard-bookings-uuid___en"
  }, {
    path: "/hotels/destination/:countrySlug",
    component: _568b8370,
    name: "hotels-destination-countrySlug___en"
  }, {
    path: "/ar/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___ar"
  }, {
    path: "/ar/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___ar"
  }, {
    path: "/bg/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___bg"
  }, {
    path: "/bg/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___bg"
  }, {
    path: "/ca/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___ca"
  }, {
    path: "/ca/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___ca"
  }, {
    path: "/cs/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___cs"
  }, {
    path: "/cs/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___cs"
  }, {
    path: "/da/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___da"
  }, {
    path: "/da/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___da"
  }, {
    path: "/de/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___de"
  }, {
    path: "/de/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___de"
  }, {
    path: "/el/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___el"
  }, {
    path: "/el/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___el"
  }, {
    path: "/es/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___es"
  }, {
    path: "/es/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___es"
  }, {
    path: "/et/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___et"
  }, {
    path: "/et/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___et"
  }, {
    path: "/fi/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___fi"
  }, {
    path: "/fi/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___fi"
  }, {
    path: "/fr/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___fr"
  }, {
    path: "/fr/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___fr"
  }, {
    path: "/he/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___he"
  }, {
    path: "/he/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___he"
  }, {
    path: "/hotels/place/:placeId?/results",
    component: _7e775a48,
    name: "hotels-place-placeId-results___en"
  }, {
    path: "/hr/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___hr"
  }, {
    path: "/hr/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___hr"
  }, {
    path: "/hu/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___hu"
  }, {
    path: "/hu/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___hu"
  }, {
    path: "/id/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___id"
  }, {
    path: "/id/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___id"
  }, {
    path: "/is/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___is"
  }, {
    path: "/is/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___is"
  }, {
    path: "/it/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___it"
  }, {
    path: "/it/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___it"
  }, {
    path: "/ja/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___ja"
  }, {
    path: "/ja/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___ja"
  }, {
    path: "/ko/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___ko"
  }, {
    path: "/ko/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___ko"
  }, {
    path: "/lt/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___lt"
  }, {
    path: "/lt/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___lt"
  }, {
    path: "/ms/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___ms"
  }, {
    path: "/ms/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___ms"
  }, {
    path: "/nl/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___nl"
  }, {
    path: "/nl/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___nl"
  }, {
    path: "/pl/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___pl"
  }, {
    path: "/pl/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___pl"
  }, {
    path: "/pt-br/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___pt-br"
  }, {
    path: "/pt-br/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___pt-br"
  }, {
    path: "/pt/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___pt"
  }, {
    path: "/pt/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___pt"
  }, {
    path: "/ro/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___ro"
  }, {
    path: "/ro/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___ro"
  }, {
    path: "/ru/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___ru"
  }, {
    path: "/ru/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___ru"
  }, {
    path: "/sk/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___sk"
  }, {
    path: "/sk/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___sk"
  }, {
    path: "/sl/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___sl"
  }, {
    path: "/sl/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___sl"
  }, {
    path: "/sr/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___sr"
  }, {
    path: "/sr/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___sr"
  }, {
    path: "/sv/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___sv"
  }, {
    path: "/sv/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___sv"
  }, {
    path: "/th/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___th"
  }, {
    path: "/th/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___th"
  }, {
    path: "/tl-ph/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___tl-ph"
  }, {
    path: "/tl-ph/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___tl-ph"
  }, {
    path: "/tr/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___tr"
  }, {
    path: "/tr/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___tr"
  }, {
    path: "/uk/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___uk"
  }, {
    path: "/uk/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___uk"
  }, {
    path: "/vi/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___vi"
  }, {
    path: "/vi/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___vi"
  }, {
    path: "/zh-cn/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___zh-cn"
  }, {
    path: "/zh-cn/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___zh-cn"
  }, {
    path: "/zh-tw/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___zh-tw"
  }, {
    path: "/zh-tw/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___zh-tw"
  }, {
    path: "/hotels/destination/:countrySlug?/:destinationSlug",
    component: _18ec7bef,
    name: "hotels-destination-countrySlug-destinationSlug___en"
  }, {
    path: "/hotels/place/:countrySlug?/:hotelId?",
    component: _e5503a8a,
    name: "hotels-place-countrySlug-hotelId___en"
  }, {
    path: "/hotels/destination/:countrySlug?/:destinationSlug/results",
    component: _5fa60fd3,
    name: "hotels-destination-countrySlug-destinationSlug-results___en"
  }, {
    path: "/ar/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___ar"
  }, {
    path: "/bg/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___bg"
  }, {
    path: "/ca/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___ca"
  }, {
    path: "/cs/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___cs"
  }, {
    path: "/da/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___da"
  }, {
    path: "/de/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___de"
  }, {
    path: "/el/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___el"
  }, {
    path: "/es/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___es"
  }, {
    path: "/et/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___et"
  }, {
    path: "/fi/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___fi"
  }, {
    path: "/fr/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___fr"
  }, {
    path: "/he/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___he"
  }, {
    path: "/hotels/destination/:countrySlug?/:destinationSlug/:hotelSlug",
    component: _d9cdb59a,
    name: "hotels-destination-countrySlug-destinationSlug-hotelSlug___en"
  }, {
    path: "/hr/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___hr"
  }, {
    path: "/hu/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___hu"
  }, {
    path: "/id/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___id"
  }, {
    path: "/is/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___is"
  }, {
    path: "/it/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___it"
  }, {
    path: "/ja/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___ja"
  }, {
    path: "/ko/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___ko"
  }, {
    path: "/lt/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___lt"
  }, {
    path: "/ms/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___ms"
  }, {
    path: "/nl/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___nl"
  }, {
    path: "/pl/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___pl"
  }, {
    path: "/pt-br/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___pt-br"
  }, {
    path: "/pt/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___pt"
  }, {
    path: "/ro/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___ro"
  }, {
    path: "/ru/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___ru"
  }, {
    path: "/sk/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___sk"
  }, {
    path: "/sl/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___sl"
  }, {
    path: "/sr/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___sr"
  }, {
    path: "/sv/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___sv"
  }, {
    path: "/th/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___th"
  }, {
    path: "/tl-ph/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___tl-ph"
  }, {
    path: "/tr/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___tr"
  }, {
    path: "/uk/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___uk"
  }, {
    path: "/vi/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___vi"
  }, {
    path: "/zh-cn/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___zh-cn"
  }, {
    path: "/zh-tw/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___zh-tw"
  }, {
    path: "/transaction/:transactionUuid?/error",
    component: _7b08ac1a,
    name: "transaction-transactionUuid-error___en"
  }, {
    path: "/transaction/:transactionUuid?/success",
    component: _141eae15,
    name: "transaction-transactionUuid-success___en"
  }, {
    path: "/hotels/:countrySlug/:citySlug?/:hotelSlug?",
    component: _003ac94d,
    name: "hotels-countrySlug-citySlug-hotelSlug___en"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
